import algoliasearch from 'algoliasearch/lite'

let client;

window.addEventListener("load", (event) => {
  client = algoliasearch(window.ALGOLIA_APP_ID, window.ALGOLIA_KEY_ID);
  loadEvents();
});

function loadEvents () {
  $('#language-dropdown-mobile').hide()
  $('#open-menu-topnavbar').on('click', openMenu)
  $('#by-department').on('click', function () { toggleMenu('apps') })
  $('#by-resources').on('click', function () { toggleMenu('resources') })
  $('#by-advice').on('click', function () { toggleMenu('advice') })
  $('#close-menu-topnavbar').on('click', closeMenu)
  $('#open-newsletter-topnavbar').on('click', openNewsletterMenu)
  $('#div-full-screen-top-navbar').on('click', closeSearchModal)
  $('#newsletter-submit-topnavbar').on('click', submitNewsletter)
  $('#searcher_two_home_htr').keyup(searchAlgolia)
  $('#div-full-screen-top-navbar').on('click', closeSearch)
  $('#manage-dropdown-language').on('click', openLanguageDropdown)
  $('header').attr('style', 'position: fixed !important')
}

$('#hotelier-resume').on('click', function() {
  $('#hotelier-options').removeClass('hidden');
});

$('#close-hotelier-options').on('click', function() {
  $('#hotelier-options').addClass('hidden');
});

$('#vendor-resume').on('click', function() {
  $('#vendor-options').removeClass('hidden');
});

$('#close-vendor-options').on('click', function() {
  $('#vendor-options').addClass('hidden');
});

function openMenu () {
  const menu = $('.top-open-menu')
  const homepage = $('.homepage')
  homepage.css('overflow', 'hidden')
  const wrapper = $('#wrapper')
  wrapper.css('overflow', 'hidden')
  menu.show()
}

function closeMenu () {
  const menu = $('.top-open-menu')
  const homepage = $('.homepage')
  homepage.css('overflow', 'auto')
  const wrapper = $('#wrapper')
  wrapper.css('overflow', 'auto')
  menu.hide()
}

function openLanguageDropdown () {
  if ($('#language-dropdown-mobile').is(':visible')) {
    $('#language-dropdown-mobile').hide()
  } else {
    $('#language-dropdown-mobile').show()
  }
}

function toggleMenu (menuType) {
  const categoryContainer = $(`.${menuType}-container`)
  const arrowDownElement = $(`#by-${menuType}-arrow-down`)

  if (arrowDownElement.hasClass('rotate-image')) {
    arrowDownElement.removeClass('rotate-image')
  } else {
    arrowDownElement.addClass('rotate-image')
  }

  if (categoryContainer.is(':visible')) {
    categoryContainer.addClass('display-none')
    categoryContainer.removeClass('display-block')
    arrowDownElement.removeClass('rotate-image')
  } else {
    categoryContainer.removeClass('display-none')
    categoryContainer.addClass('display-block')
  }

  const otherMenuTypes = ['apps', 'resources', 'advice'];
  otherMenuTypes.splice(otherMenuTypes.indexOf(menuType), 1);

  for (const otherMenuType of otherMenuTypes) {
    const otherCategoryContainer = $(`.${otherMenuType}-container`);
    const otherArrowDownElement = $(`#by-${otherMenuType}-arrow-down`);

    otherCategoryContainer.addClass('display-none');
    otherCategoryContainer.removeClass('display-block');
    otherArrowDownElement.removeClass('rotate-image');
  }
}

function openNewsletterMenu () {
  const newsletterContainer = $('.newsletter-container')
  const menuContainer = $('.top-open-menu')
  if ($('.newsletter-container').is(':visible')) {
    newsletterContainer.addClass('display-none')
    newsletterContainer.removeClass('display-block')
  } else {
    newsletterContainer.removeClass('display-none')
    newsletterContainer.addClass('display-block')
  }

  menuContainer.css('overflow', 'auto')
}
function closeSearchModal () {
  const fullScren = $('#divFullScreenSearcherTwo_htr')
  $(fullScren).css({ 'display': 'none' })
  const search = $('#drowdown_searcher_two_htr')
  $(search).css({ 'visibility': 'hidden' })
  $(search).fadeOut(100)
}

function setResultIntoDom (result, query, sectionsInDom, namesInDom, idSearch) {
  for (var k = 0; k < sectionsInDom.length; k++) {
    if (result[sectionsInDom[k]]) {
      var html = `<div class="search-item">
        <div class="search-item__header">
          ${namesInDom[k]}
          <span>(${result[sectionsInDom[k]].nbHits} results)</span>
          <a href="/s/${query}/${sectionsInDom[k]}">View more</a>
        </div>
        <ul class="search-item__body">`

      for (var j = 0; j < result[sectionsInDom[k]].hits.length; j++) {
        if (sectionsInDom[k] === 'products') {
          html += `<li class="search-item__body-product">
          <a href="${result[sectionsInDom[k]].hits[j].url}">
          <img src="${result[sectionsInDom[k]].hits[j].logo ? result[sectionsInDom[k]].hits[j].logo : '/images/company-default-logo.png'}"></img>
          <div>
            <span class="search-product__title">${result[sectionsInDom[k]].hits[j].title}</span>
            <span class="search-product__category">${result[sectionsInDom[k]].hits[j].category.title}</span>
          </div>
          <input type="hidden" value="${result[sectionsInDom[k]].hits[j].rating}"></input>
          `
          if (result[sectionsInDom[k]].hits[j].rating) {
            const rating = Number(result[sectionsInDom[k]].hits[j].rating)
            html += '<ul class="rating">'
            for (var i = 1; i <= 5; i++) {
              html += '<li>'
              if (rating >= i) {
                html += '<img src="/images/star-full-blue.svg" width="18"></img>'
              } else if (i - rating <= 0.5) {
                html += '<img src="/images/star-half-blue.svg" width="18"></img>'
              } else {
                html += '<img src="/images/star-empty-blue.svg"></img>'
              }
              html += '</li>'
            }
            html += '</ul>'
          }
          html += '</a>'
        } else {
          html += `<li>
          <a href="${result[sectionsInDom[k]].hits[j].url}">
            <span>${result[sectionsInDom[k]].hits[j].title}</span>
          </a>`
        }
      }
      html += '</li></ul>'
      html += '</div>'
      $(`#${idSearch}${sectionsInDom[k]}`).empty()
      $(`#${idSearch}${sectionsInDom[k]}`).append(html)
    }
  }
}

function indexName (key) {
  return window.ENVIRONMENT + '_' + key
}

function handleResponse (results) {
  return results.reduce(
    (accumulator, item) => {

      if (item.index === indexName("categories_index"))
        accumulator.categories = item;
      if (item.index === indexName("products_index"))
        accumulator.products = item;
      if (item.index === indexName("posts_index"))
        accumulator.articles = item;
      if (
        [
          indexName("resources_index"),
          indexName("buyers_guides_index"),
        ].includes(item.index)
      ) {
        if (!Object.keys(accumulator.resources).length) {
          accumulator.resources = item;
        } else {
          accumulator.resources.nbHits += item.nbHits;
          accumulator.resources.hits = [
            ...accumulator.resources.hits,
            ...item.hits,
          ];
          accumulator.resources.hits = accumulator.resources.hits.slice(
            0,
            3
          );
        }
      }

      return accumulator;
    },
    { categories: {}, articles: {}, resources: {}, products: {} }
  );
}

// key pressed in the search input
function searchAlgolia (e) {
  if (e.target.value.length === 0 && $('#drowdown_searcher_two_htr').css({ 'visibility': 'visible' })) {
    $('#drowdown_searcher_two_htr').css({ 'visibility': 'hidden' })
    $('#drowdown_searcher_two_htr').fadeOut(100)
  }
  var value = e.target.value
  const sectionsInDom = ['categories', 'products', 'articles', 'resources']
  const namesInDom = ['Categories', 'Products', 'Articles', 'Free Resources & Guides']
  if (value.length < 3) {
    sectionsInDom.forEach((section) => {
      $(`#${section}`).empty()
    })
    return false
  } else {
    const searchInAlgolia = [
      {
        indexName: indexName('categories_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('products_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('posts_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('resources_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('buyers_guides_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      }
    ]
    client.multipleQueries(searchInAlgolia).then(function ({ results }) {
      let result = { ...handleResponse(results) };
      let search = $('#drowdown_searcher_two_htr')
      $(search).css({ 'visibility': 'visible' })
      $(search).fadeIn(100)
      let fullScren = $('#divFullScreenSearcherTwo_htr')
      $(fullScren).css({ 'display': 'block' })
      setResultIntoDom(result, value, sectionsInDom, namesInDom, 'searcher_two_home_htr')
    }).catch(function (err) {
    })
  }
}

function submitNewsletter () {
  const url = window.location.href
  const email = $('#newsletter-topnavbar-email').val()
  $.post('/register-neswleter', {
    'email': email,
    'signup_url': url
  })
    .done(function () {
      $('#alertSuccess_htr').show().delay(4000).hide(0)
    })
    .fail(function (e) {
      let $errorText = e.responseJSON?.message ?
      e.responseJSON.message : 'Invalid email format'
      $('#alertError_htr')
        .text($errorText)
        .show()
        .delay(4000)
        .hide(0)
    })
}

function closeSearch () {
  const fullScren = $('#div-full-screen-top-navbar')
  $(fullScren).css({ 'display': 'none' })
  const search = $('#drowdown_searcher_two_htr')
  $(search).css({ 'visibility': 'hidden' })
  $(search).fadeOut(100)
}

export { setResultIntoDom, searchAlgolia }
